import React from 'react';
import './index.css';

export const MemoWhy = React.memo(function Why() {
    return (
        <div className="why-container">
            <span className="why-title">Почему мы</span>
            <div className="why-icons">
                <div className="why-block">
                    <div className="why-block-icon apple-icon"></div>
                    <div className="why-block-text">
                        <div className="why-block-primary">Состав</div>
                        <div className="why-block-secondary">Только из свежесобранных ягод</div>
                    </div>
                </div>
                <div className="why-block">
                    <div className="why-block-icon nature-icon"></div>
                    <div className="why-block-text">
                        <div className="why-block-primary">Натуральность</div>
                        <div className="why-block-secondary">Не содержит красителей, консервантов и загустителей</div>
                    </div>
                </div>
                <div className="why-block">
                    <div className="why-block-icon pamphlet-icon"></div>
                    <div className="why-block-text">
                        <div className="why-block-primary">Ассортимент</div>
                        <div className="why-block-secondary">Большой выбор варенья и меда</div>
                    </div>
                </div>
            </div>
        </div>
    );
});