import React from 'react';
import logo from '../../images/logo.png';
import './index.css';


export const MemoFooter = React.memo(function Footer() {
    return (
        <footer className='footer'>
            <div className='footer-content'>
                <img className='footer-logo' src={logo} alt="logo"></img>
                <span>2022</span>
            </div>
        </footer>
    );
});