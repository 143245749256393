import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom';
import './admin.css'

export default function Admin() {
    const [fairs, setFairs] = useState(false);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();
    const fairsRef = useRef(fairs);
    const currentFair = useRef(null);
    const modalMethod = useRef(null);

    useEffect(() => {
        fairsRef.current = fairs;
    }, [fairs])

    useEffect(() => {
        if (!localStorage.getItem('token')) navigate('/login');

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('token', `${localStorage.getItem('token')}`);
        const options = {
            method: 'GET',
            headers: headers,
        }
        fetch(`${process.env.REACT_APP_API_ADDRESS}/list`, options)
            .then(async res => {
                const data = await res.json()
                setFairs(data)
            })
        .catch(err => console.log(err))
    }, [])

    function handleDelete(name){
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('token', `${localStorage.getItem('token')}`);
        const options ={
            method: 'POST',
            headers: headers,
            body: JSON.stringify({name}),
        }
        fetch(`${process.env.REACT_APP_API_ADDRESS}/delete`, options)
            .then(async res => {
                if (res.status !== 200) throw new Error(res.statusText);
                const newFairs = fairsRef.current.content.filter(fair => fair.name !== name);
                setFairs({...fairsRef.current, content: newFairs})
            })
            .catch(err => console.log(err))
    }

    function handleEdit(e){
        e.preventDefault();
        let data = Object.fromEntries(new FormData(e.target).entries());
        data.date = new Date(data.date).getTime()/1000;
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('token', `${localStorage.getItem('token')}`);
        const options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        }
        fetch(`${process.env.REACT_APP_API_ADDRESS}/update`, options)
            .then(async res => {
                if (res.status !== 200) throw new Error(res.statusText);
                const newFairs = fairsRef.current.content.map(fair => 
                    fair.name === currentFair.current.name ?
                        {...data}
                        : fair
                    );
                    setFairs({...fairsRef.current, content: newFairs})
                setModal(false);
            })
            .catch(err => console.log(err))
    }

    function handleCreate(e){
        e.preventDefault();
        let data = Object.fromEntries(new FormData(e.target).entries());
        console.log(data);
        data.date = new Date(data.date).getTime()/1000;
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('token', `${localStorage.getItem('token')}`);
        const options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        }
        fetch(`${process.env.REACT_APP_API_ADDRESS}/create`, options)
            .then(async res => {
                if (res.status !== 200) throw new Error(res.statusText);
                const newFairs = fairsRef.current.content.concat(data);
                setFairs({...fairsRef.current, content: newFairs})
                setModal(false);
            })
            .catch(err => console.log(err))
    }

    function toggleModal(fair, method){
        switch(method){
            case 'edit':
                modalMethod.current = handleEdit;
                currentFair.current = fair;
                break;
            case 'create':
                modalMethod.current = handleCreate;
                currentFair.current = null;
                break;
        }
        setModal(!modal);
    }

    return (
        <>
            <div className='adminContainer'>
                <div className='fairsContainer'>
                    <div className='fairHeader'>
                        <span>Название</span>
                        <span>Дата</span>
                        <span>Время начала</span>
                        <span>Время конца</span>
                        <span>Широта</span>
                        <span>Долгота</span>
                        <span>Адрес</span>
                        <span> </span>
                    </div>
                    {
                        fairs && fairs.content.map(fair => {
                            return (
                                <div className='fair' key={fair.name}>
                                    <span>{fair.name}</span>
                                    <Moment unix format='DD/MM/YYYY'>{fair.date}</Moment>
                                    <span>{fair.start_work}</span>
                                    <span>{fair.end_work}</span>
                                    <span>{fair.latitude}</span>
                                    <span>{fair.longitude}</span>
                                    <span>{fair.address}</span>
                                    <span>
                                        <div className='buttonContainer'>
                                            <div onClick={()=>handleDelete(fair.name)}>Удалить</div>
                                            <div onClick={()=>toggleModal(fair, 'edit')}>Редактировать</div>
                                        </div>
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='buttonContainer'>
                    <div onClick={()=>toggleModal(null, 'create')}>Создать</div>
                </div>
            </div>
            {
                modal && (
                    <>
                        <div className='editModalCover' onClick={() => setModal(false)} />
                        <div className='editModalContainer'>
                            <div className='editModal'>
                                <form onSubmit={modalMethod.current}>
                                    <input defaultValue={currentFair.current?.name} readOnly={currentFair.current?.name} name='name' placeholder='Название' />
                                    <input defaultValue={currentFair.current?.date && new Date(currentFair.current?.date*1000).toISOString().substring(0,10)} type='date' name='date' placeholder='Дата' />
                                    <input defaultValue={currentFair.current?.start_work} name='start_work' placeholder='Время начала' />
                                    <input defaultValue={currentFair.current?.end_work} name='end_work' placeholder='Время конца' />
                                    <input defaultValue={currentFair.current?.latitude} name='latitude' placeholder='Широта' />
                                    <input defaultValue={currentFair.current?.longitude} name='longitude' placeholder='Долгота' />
                                    <input defaultValue={currentFair.current?.address} name='address' placeholder='Адрес' />
                                    <input type='submit' value='Сохранить'/>
                                </form>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}
