import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./index.css";
import { MemoMap } from "./map";
import ReactTooltip from "react-tooltip";
import { Button, Icon, Tab } from "@mui/material";

const monthNames = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня",
  "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"
];

const tabStyle = {
  fontFamily: "Open Sans",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "30px",
  letterSpacing: "0em",
  textAlign: "left"
};

export const MemoMaps = function Maps() {
  const [value, setValue] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [fairs, setFairs] = useState([]);

  useEffect(() => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const options = {
      method: "GET",
      headers: headers
    }
    fetch(`${process.env.REACT_APP_API_ADDRESS}/list`, options)
      .then(async res => {
        const data = await res.json()
        const newMarkers = data.content.map((fair,index) => ({
          name: fair.name,
          coordinates: [fair.latitude, fair.longitude],
          markerOffsetX: (index%2 === 0)?20:-20,
          markerOffsetY: (index%2 === 0)?-20:20
        }))
        setFairs(data.content)
        setMarkers(newMarkers)
      })
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="maps-container">
      <div className="maps-header">
        <span className="maps-title">График ярмарок</span>
        <div className="tabs-container">
          {/* <Button className={"tab" + (value === 0 ? " selected" : "")} onClick={() => setValue(0)}>2 февраля</Button> */}
          {
            fairs.length>0 && fairs.map((fair, index) => {
              const date = new Date(fair.date*1000);
              return (
                <Button key={fair.name} className={"tab" + (value === index ? " selected" : "")} onClick={() => setValue(index)}>
                  {date.getDay()} {monthNames[date.getMonth()]} {date.getFullYear()} года
                </Button>
              )
            })
          }
        </div>
        <div className="tab-header-image"></div>
      </div>
      <div className="tabs-content">
        {value !== false ? <>
          <span className="content-header">Ярмарка пройдет:</span>
          <div className="content-icons">
            <div className="block">
              <div className="clock-icon"></div>
              <div className="block-text">
                <div className="block-primary">
                  {new Date(fairs[value].date*1000).getDay()} &nbsp;
                  {monthNames[new Date(fairs[value].date*1000).getMonth()]} &nbsp;
                  {/* {new Date(fairs[value].date*1000).getFullYear()} года */}
                </div>
                <div className="block-secondary">Открытие: {fairs[value].start_work} Закрытие: {fairs[value].end_work}</div>
              </div>
            </div>
            <div className="block">
              <div className="map-point-icon"></div>
              <div className="block-text">
                <div className="block-primary">{fairs[value].name}</div>
              </div>
            </div>
          </div>
        </>
        :
        <>
          <span className="content-header">Ярмарка пройдет:</span>
          <div className="content-icons">
            <div className="block">
              <div className="clock-icon"></div>
              <div className="block-text">
                <div className="block-primary">
                  {
                    fairs.map((fair, index) => {
                      const date = new Date(fair.date*1000);
                      if (index === fairs.length - 1) return `${date.getDay()} ${monthNames[date.getMonth()]}`
                      return `${date.getDay()} ${monthNames[date.getMonth()]}, `
                    })
                  }
                </div>
                {/* <div className="block-secondary">Открытие: {fairs[value].start_work} Закрытие: {fairs[value].end_work}</div> */}
              </div>
            </div>
            <div className="block">
              <div className="map-point-icon"></div>
              <div className="block-text">
                <div className="block-primary">
                  г.{
                    fairs.map((fair, index) => {
                      if (index === fairs.length - 1) return `${fair.name}`
                      return `${fair.name}, `
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </>
        }
        <MemoMap markers={markers}></MemoMap>
      </div>
    </div>
  );
};
