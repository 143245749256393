import React, { useState } from 'react';
import './index.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import temp1 from '../../images/1.png'
import temp2 from '../../images/2.png'
import temp3 from '../../images/3.png'
import temp4 from '../../images/4.png'
const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
    1440: { items: 4 }
};

const images = [temp1, temp2, temp3, temp4];

const createItems = (length, [handleClick]) => {
    let deltaX = 0;
    let difference = 0;
    const swipeDelta = 20;

    return Array.from({ length }).map((item, i) => (
        <div
            key={i}
            data-value={i + 1}
            className="item"
            onMouseDown={(e) => (deltaX = e.pageX)}
            onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
            onClick={() => (difference < swipeDelta) && handleClick(i)}
            style={{ backgroundImage: "url(" + images[i] + ")", margin: "15px" }}
        >
        </div>
    ));
};

const Carousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [items] = useState(createItems(4, [setActiveIndex]));
    const slidePrev = () => setActiveIndex(activeIndex - 1);
    const slideNext = () => setActiveIndex(activeIndex + 1);
    const syncActiveIndex = ({ item }) => setActiveIndex(item);

    return (
        <AliceCarousel
            mouseTracking
            disableDotsControls
            disableButtonsControls
            autoHeight
            infinite
            items={items}
            activeIndex={activeIndex}
            responsive={responsive}
            onSlideChanged={syncActiveIndex}
        />);
};

export const MemoSlider = React.memo(function Slider() {
    return (
        <div className="slider-container">
            <span className="slider-title">Более 30 видов варенья, посмотрите наиболее популярные</span>
            <Carousel />
        </div>
    );
});