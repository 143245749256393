import { Box, Button, Modal, TextField } from "@mui/material";
import React, { memo, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const MemoAddPointModal = memo(function AddPointModal({ addPoint }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [coords, setCoords] = useState("");
  const [title, setTitle] = useState("");
  const handleCoords = (event) => setCoords(event.target.value);
  const handleTitle = (event) => setTitle(event.target.value);
  const handleAdd = () => {
    addPoint({
      name: title,
      coordinates: coords.split(",")?.map((coord) => +coord).reverse(),
      markerOffset: -15,
    });
    handleClose();
  };
  return (
    <>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TextField
            id="standard-basic"
            value={title}
            onChange={handleTitle}
            label="Title"
            variant="standard"
          />
          <TextField
            id="standard-basic"
            value={coords}
            onChange={handleCoords}
            label="Google maps coords"
            variant="standard"
          />
          <Button onClick={handleAdd}>add point</Button>
        </Box>
      </Modal>
    </>
  );
});
