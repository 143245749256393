import React from 'react';
import './index.css';

export const MemoMainImage = React.memo(function MainImage() {
    return (
        <div className='main-image-container'>
            <div className='main-image'></div>
            <div className='main-text'>
                <span>
                    Мы рады представить Вашему вниманию натуральные и при этом уникальные продукты собственного производства, которые способны не только удивить, но и принести пользу.
                </span>
            </div>
        </div>
    );
});