import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import * as d3 from "d3-geo";
import { MemoAddPointModal } from "./addPointModal";
import { CircularProgress } from "@mui/material";
// const geoUrl =
//   "https://raw.githubusercontent.com/Kreozot/russian-geo-data/master/geo.json";
const geoUrl =
  "https://raw.githubusercontent.com/zarkzork/russia-topojson/master/russia.json";
const projection = d3
  .geoMercator()
  .rotate([-120, 0])
  .scale(700 / Math.PI)
  .translate([450, 700])
  .precision(0.1);

export const MemoMap = function Map({ markers }) {
  const defaultProps = {
    center: {
      lat: 55.4507,
      lng: 37.3656
    },
    zoom: 11
  };
  {
    // const [geo,setGeo] = useState(null);
    // useEffect(()=>{
    //   axios.get(geoUrl).then(response=>{
    //     setGeo(response.data);
    //   })
    // },[]);
  }
  return (
    <div className="map-container">
      <ComposableMap
        projection={projection}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies
              .map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="hsl(166, 91%, 26%)"
                  stroke="hsl(166, 69%, 45%)"
                  style={{
                    default: {
                      outline: "none"
                    },
                    hover: {
                      outline: "none",
                    },
                    pressed: {
                      outline: "none",
                    }
                  }}
                />
              ))
          }
        </Geographies>
        {markers.length>0 && markers.map(({ name, coordinates, markerOffsetX, markerOffsetY }) => {
          let x = markerOffsetX;
          let y = markerOffsetY;
          let shift = x<0?-50:50;
          let textShift = x<0?-25:25;
          let textShiftY = y>0?15:-5;
          return (<Marker key={name} coordinates={coordinates}>
              <circle r={5} fill="#DE3636" strokeWidth={0} />
              <svg className="marker-line" fill="transparent" stroke="#DE3636">
                <polyline
                  points={"0,0 " +
                    x + "," + y +
                    " " + (x + shift) + "," + y}
                ></polyline>
              </svg>
              <text className="marker-title" textAnchor="middle" y={y+textShiftY} x={x+textShift}>{name}</text>
            </Marker>
          )
        })}
      </ComposableMap>
    </div>
  );
};
