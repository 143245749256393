import logo from './logo.svg';
import './App.css';
import {MemoHeader} from './features/header/header';
import { MemoBody } from './features/body/body';

function App() {
  return (
    <div className="App">
      <MemoHeader/>
      <MemoBody/>
    </div>
  );
}

export default App;
