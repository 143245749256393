import React from 'react';
import logo from '../../logo.svg';
import './index.css';
import { Icon } from '@mui/material'


export const MemoHeader = React.memo(function Header() {
    return (
        <header className='header'>
            <div className='header-image'></div>
            <div className='header-content'>
                <div className='company-name'>
                    <span>
                        Ягоды на пару
                    </span> 
                </div>
                <div className='contact-number'>
                    <Icon className="material-icon" fontSize='small'>phone</Icon>
                    <div className='numbers'>
                        <a href="tel:+79215629714" className='phone-number'>
                            +79215629714
                        </a>
                        <a href="tel:+79212469731" className='phone-number additional'>
                            +79212469731
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
});
