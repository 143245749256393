import React from 'react';
import './index.css';

export const MemoVirtues = React.memo(function Virtues() {
    return (
        <div className="virtues-container">
            <div className='virtues-column'>
                <div className='virtues-block'>
                    <div className='virtues-icon stra-icon'></div>
                    <div className='virtues-text'>Большое содержание ягод и плодов</div>
                </div>
                <div className='virtues-block'>
                    <div className='virtues-icon sugar-icon'></div>
                    <div className='virtues-text'>Без добавления сахара</div>
                </div>
                <div className='virtues-block'>
                    <div className='virtues-icon leaf-icon'></div>
                    <div className='virtues-text'>Плоды и ягоды собраны в экологически чистых районах Архангельской и Вологодской области</div>
                </div>
            </div>
            <div className='virtues-column main-barries-image'></div>
            <div className='virtues-column'>
                <div className='virtues-block'>
                    <div className='virtues-icon jam-icon'></div>
                    <div className='virtues-text'>Изготавливаем по методу многократного упаривания при температуре 40 градусов, сохраняя полезные свойства ягод</div>
                </div>
                <div className='virtues-block'>
                    <div className='virtues-icon calendar-icon'></div>
                    <div className='virtues-text'>Срок хранение 1 год в холодильнике Среднее содержание в 100 граммах варенья - 20 грамм углеводов</div>
                </div>
                <div className='virtues-block'>
                    <div className='virtues-icon sunrise-icon'></div>
                    <div className='virtues-text'>Плоды и ягоды собраны в экологически чистых районах России</div>
                </div>
            </div>
        </div>
    );
});