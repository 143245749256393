import React from 'react';
import { MemoAboutUs } from '../aboutUs/aboutUs';
import { MemoFooter } from '../footer/footer';
import { MemoMainImage } from '../mainImage/mainImage';
import { MemoMaps } from '../maps/maps';
import { MemoSlider } from '../productSlider/productSlider';
import { MemoVirtues } from '../virtues/virtues';
import { MemoWhy } from '../whyWe/whyWe';
import './index.css';

export const MemoBody = React.memo(function Body() {
    return (
        <div className='main-container'>
            <MemoMainImage/>
            <MemoMaps/>
            <MemoWhy/>
            <MemoSlider/>
            <MemoVirtues/>
            <MemoAboutUs/>
            <MemoFooter/>
        </div>
    );
});