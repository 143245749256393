import React from 'react'
import { useNavigate } from 'react-router-dom';
import './login.css'

export default function Login() {
    const navigate = useNavigate();

    function handleLogin(e){
        e.preventDefault();
        const data = Object.fromEntries(new FormData(e.target).entries());
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        }
        fetch(`${process.env.REACT_APP_API_ADDRESS}/login`, options)
            .then(async res => {
                if (res.status !== 200) throw new Error(res.statusText);
                const data = await res.json();
                localStorage.setItem('token', data.content.token);
                navigate('/admin');
            })
            .catch(err => console.log(err))
    }

    return (
        <div className='loginContainer'>
            <form onSubmit={handleLogin}>
                <input type='text' name='login' placeholder='Логин' />
                <input type='password' name='password' placeholder='Пароль' />
                <input type='submit' value='Войти' />
            </form>
        </div>
    )
}
