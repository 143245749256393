import React from 'react';
import './index.css';
import preview from '../../images/preview.png'

export const MemoAboutUs = React.memo(function AboutUs() {
    return (
        <div className='about-us-container'>
            <div className='image-column'></div>
            <div className='about-us-column'>
                <span className='about-us-title'>О НАС</span>
                <div className='about-text'>
                    В современной жизни найти натуральную продукцию - задача не самая легкая, несмотря на широкий ассортимент в супермаркетах.
                    Обычно цена на домашние продукты куда выше, чем на предложения сетевых магазинов. Потому многие из нас делают выбор именно в пользу массового продукта, приемлемого по своей стоимости, но уступающего по качеству.
                    Купить варенье без сахара, натуральный мёд или ягоды без риска нарваться на некачественный продукт ненатурального происхождения – это не миф, а реальность.
                </div>
                <span className='about-us-title'>Наши клиенты</span>
                <div className='card-panel'>
                    <div className='card'>
                        <div className='card-image example-image-1'></div>
                    </div>
                    <div className='card'>
                        <div className='card-image example-image-2'></div>
                    </div>
                    <div className='card'>
                        <div className='card-image example-image-3'></div>
                    </div>
                    <div className='card'>
                        <div className='card-image example-image-4'></div>
                    </div>
                    <div className='card'>
                        <div className='card-image example-image-5'></div>
                    </div>
                    <div className='card'>
                        <div className='card-image example-image-6'></div>
                    </div>
                    <div className='card'>
                        <div className='card-image example-image-7'></div>
                    </div>
                </div>
            </div>
        </div>
    );
});